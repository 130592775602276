enum AppEnv {
  Development = 'development',
  Local = 'local',
  Production = 'production',
  Staging = 'staging',
}

const isAppEnv = (env: unknown): env is AppEnv => {
  return typeof env === 'string' && Object.values(AppEnv).includes(env as AppEnv)
}

const DAPP_URL_MAP = {
  development: 'dev-app.ethena.fi',
  local: 'localhost:3009',
  production: 'app.ethena.fi',
  staging: 'staging-app.ethena.fi',
}

const LANDING_URL_MAP = {
  development: 'dev-landing.ethena.fi',
  local: 'localhost:3000',
  production: 'ethena.fi',
  staging: 'staging-landing.ethena.fi',
}

const CLAIM_URL_MAP = {
  development: 'dev-claim.ethena.fi',
  local: 'localhost:3002',
  production: 'claim.ethena.fi',
  staging: 'staging-claim.ethena.fi',
}

const NETWORK_URL_MAP = {
  development: 'dev-network.ethena.fi',
  local: 'localhost:3004',
  production: 'network.ethena.fi',
  staging: 'staging-network.ethena.fi',
}

const CALCULATOR_URL_MAP = {
  development: 'dev-calculator.ethena.fi',
  local: 'localhost:3001',
  production: 'ethena.vfat.io',
  staging: 'staging-calculator.ethena.fi',
}

export const APP_ENV: AppEnv = isAppEnv(process.env.NEXT_PUBLIC_APP_ENV)
  ? process.env.NEXT_PUBLIC_APP_ENV
  : AppEnv.Production

export const SHORT_DAPP_URL = DAPP_URL_MAP[APP_ENV]
export const HTTP_DAPP_URL = 'http://' + SHORT_DAPP_URL
export const HTTP_WWW_DAPP_URL = 'http://www.' + SHORT_DAPP_URL
export const DAPP_URL = APP_ENV === 'local' ? HTTP_DAPP_URL : 'https://' + SHORT_DAPP_URL
export const WWW_DAPP_URL = APP_ENV === 'local' ? HTTP_WWW_DAPP_URL : 'https://www.' + SHORT_DAPP_URL

export const SHORT_LANDING_URL = LANDING_URL_MAP[APP_ENV]
export const HTTP_LANDING_URL = 'http://' + SHORT_LANDING_URL
export const HTTP_WWW_LANDING_URL = 'http://www.' + SHORT_LANDING_URL
export const LANDING_URL = APP_ENV === 'local' ? HTTP_LANDING_URL : 'https://' + SHORT_LANDING_URL
export const WWW_LANDING_URL = APP_ENV === 'local' ? HTTP_WWW_LANDING_URL : 'https://www.' + SHORT_LANDING_URL

export const SHORT_CLAIM_URL = CLAIM_URL_MAP[APP_ENV]
export const HTTP_CLAIM_URL = 'http://' + SHORT_CLAIM_URL
export const HTTP_WWW_CLAIM_URL = 'http://www.' + SHORT_CLAIM_URL
export const CLAIM_URL = APP_ENV === 'local' ? HTTP_CLAIM_URL : 'https://' + SHORT_CLAIM_URL
export const WWW_CLAIM_URL = APP_ENV === 'local' ? HTTP_WWW_CLAIM_URL : 'https://www.' + SHORT_CLAIM_URL

export const SHORT_NETWORK_URL = NETWORK_URL_MAP[APP_ENV]
export const HTTP_NETWORK_URL = 'http://' + SHORT_NETWORK_URL
export const HTTP_WWW_NETWORK_URL = 'http://www.' + SHORT_NETWORK_URL
export const NETWORK_URL = APP_ENV === 'local' ? HTTP_NETWORK_URL : 'https://' + SHORT_NETWORK_URL
export const WWW_NETWORK_URL = APP_ENV === 'local' ? HTTP_WWW_NETWORK_URL : 'https://www.' + SHORT_NETWORK_URL

export const SHORT_CALCULATOR_URL = CALCULATOR_URL_MAP[APP_ENV]
export const HTTP_CALCULATOR_URL = 'http://' + SHORT_CALCULATOR_URL
export const HTTP_WWW_CALCULATOR_URL = 'http://www.' + SHORT_CALCULATOR_URL
export const CALCULATOR_URL = APP_ENV === 'local' ? HTTP_CALCULATOR_URL : 'https://' + SHORT_CALCULATOR_URL
export const WWW_CALCULATOR_URL = APP_ENV === 'local' ? HTTP_WWW_CALCULATOR_URL : 'https://www.' + SHORT_CALCULATOR_URL

export const GITBOOK_URL = 'https://docs.ethena.fi'
